  
// $primary:#17258b;
$primary:#040b41;
$info:#01F0F3;
$black:#021F38;
$dark:#000207;
$danger: #FF0202;
$success: #00A149;
$secondary: #B4B4B4;
$purpal: #5f0b82;
$light: white;
$charcol:#202123;
$theme-colors: ("primary": $primary,
"danger": $danger,
"success": $success,
"secondary": $secondary,
"light": $light,
"dark": $dark,
'info':$info,
'black':$black,
'purpal':$purpal,
'charcol':$charcol,
);
