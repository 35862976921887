.mainHome {
    background: radial-gradient(#040b41, #000207);
    min-height: 100vh;
    .landingPageHeader {
        height: 100vh;
        width: 100%;
        color: #d5f1f6;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .content {
            max-width: 550px;
            width: 100%;
            z-index: 2;
            pointer-events: none;
        }
        .homeParticals {
            height: 100vh;
            width: 100%;
            position: absolute;
            z-index: 1;
        }
    }
}

.menuList {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
    li {
        a {
            pointer-events: all;
            color: white;
            text-decoration: none;
            padding: 1rem 1.5rem;
            display: block;
        }
    }
    &.all {
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        justify-content: center;
        
        
    }
}
.App-logo{
    width: 100%;
    max-width: 400px;
}